import classNames from "classnames"
import styles from "./EventCard.module.scss"
import { useNavigate } from "react-router-dom"
import DateTag from "../DateTag/DateTag"

const EventCard = ({ className, event, inEventList, key }) => {
  const navigate = useNavigate()

  if (!event) {
    return <div className={classNames(styles.card, className, styles.loader)} />
  }

  const truncatedTitle =
    (event?.short_title || event?.title).length > 40
      ? `${(event?.short_title || event?.title).substring(0, 40)}...`
      : event?.short_title || event?.title

  return (
    <div
      className={classNames(styles.cardBorder, inEventList && styles.fullWidth)}
      key={key}
      onClick={() => {
        navigate(`/event/${event.event_id}`)
      }}
    >
      <div className={classNames(styles.cardContainer, className)}>
        <div
          className={styles.card}
          // glows
          // style={
          //   !inEventList
          //     ? {
          //         backgroundImage: event?.image
          //           ? `url(${event.image})`
          //           : `url(https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png)`,
          //       }
          //     : {}
          // }
        >
          <picture className={styles.cardImg}>
            <source srcset={event.image_avif} type="image/avif" />
            <source srcset={event.image_webp} type="image/webp" />
            <img
              src={event.image}
              className={classNames(
                styles.cardImg,
                inEventList && styles.fullWidthImg
              )}
              alt={event.title}
            />
          </picture>
          <DateTag event={event} className={styles.dateTag} />

          <div className={styles.cardBottom}>
            <div className={styles.titleContainer}>
              <h3>{event?.short_title || event?.title}</h3>
              <p>
                <span>{event?.venue_name}</span>
              </p>
            </div>

            <div className={styles.priceContainer}>
              <span>All-In</span>
              <p className={styles.priceDescription}>
                <span className={styles.price} style={{textWrap:'nowrap'}}>
                  ${event?.min_ticket_price / 100}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventCard
