import React, { useRef, useEffect, useState } from "react"
import { nanoid } from "nanoid"
import styles from "./SeatMap.module.scss"
import { CloseCircle, Map, X } from "../../css/icons"
import classNames from "classnames"

import mapStyles from "./styles.json"
import Button from "../Button/Button"

const SeatMap3d = ({
  clearSelection,
  config,
  ticketGroup,
  translationRules,
  setShow3dMap,
  setMinimizeMap,
  showClearButton,
}) => {
  const containerId = useRef(`viewer-${nanoid()}`)
  const viewer = useRef(null)
  const moduleLoaded = useRef(false)
  const mappedSections = useRef({})
  const [viewNotAvailable, setViewNotAvailable] = useState(false)
  const [showCloseButton, setShowCloseButton] = useState(false)
  const [mapExists, setMapExists] = useState(false)

  // translate xp section to 3ddv
  function translateSecName(section) {
    //const rules = translations[config.venueId]
    const rules = translationRules
    let translatedSec

    if (rules && rules.sections) {
      const match = rules.sections
        .filter((r) => {
          const regex = new RegExp(r.rule, "gi")
          const matches = regex.exec(section)
          return matches
        })
        .map((r) => {
          const regex = new RegExp(r.rule, "gi")
          const matches = regex.exec(section)
          let newSec
          if (r.replace.length > 0) {
            newSec = r.replace.reduce((tem, rep) => {
              return tem.replace("${" + rep + "}", matches.groups[rep])
            }, r.template)
          } else {
            // Full replacement of section name
            newSec = r.template
          }

          return newSec
        })
      if (match.length > 0) {
        translatedSec = match[0]
      } else {
        translatedSec = `S_${section}`
      }
    } else {
      translatedSec = `S_${section}`
    }
    if (mappedSections.current[translatedSec]) {
      mappedSections.current[translatedSec].add(section)
    } else {
      mappedSections.current[translatedSec] = new Set([section])
    }
    return translatedSec
  }

  function startViewer() {
    const load_options = {
      venue_id: config.venueId,
      view_id: translateSecName(ticketGroup),
    }

    if (viewer.current) {
      viewer.current.loadView3d(load_options).catch((err) => {
        console.error(err)
      })
    }
  }

  useEffect(() => {
    if (!ticketGroup) {
      viewer.current?.reset()
      //   moduleLoaded.current = false
    }

    var input_options = {
      container: containerId.current,
    }

    if (config?.venueId && !moduleLoaded.current) {
      window.DVM.loadModule("3d_viewer", input_options)
        .then(function (viewer3d) {
          const load_options = {
            venue_id: config.venueId,
            view_id: translateSecName(ticketGroup),
          }

          moduleLoaded.current = true
          viewer.current = viewer3d

          viewer.current.checkView3d(load_options).then((view3d) => {
            if (view3d) {
              startViewer()
              setShowCloseButton(true)

              setTimeout(() => {
                setMapExists(true)
                setShow3dMap(true)
              }, 1000)
            } else {
              setViewNotAvailable(true)
              setShow3dMap(false)
            }
          })
        })
        .catch(function (err) {
          console.error(err, `ERROR loading module ${err}`)
        })
    }

    return () => {
      viewer.current?.reset()
      moduleLoaded.current = false
    }
  }, [])

  useEffect(() => {
    if (ticketGroup) {
      startViewer()
    }
  }, [ticketGroup])

  if (viewNotAvailable) {
    return null
  }

  return (
    <div
      id={containerId.current}
      className={classNames(styles.seatmap3d, mapExists && styles.showMap)}
    >
      {showCloseButton && showClearButton && (
        <X
          className={styles.close}
          onClick={() => {
            setShowCloseButton(false)
            clearSelection()
            setViewNotAvailable(true)
            viewer.current.close()
            setMinimizeMap(false)
          }}
        />
      )}
      {window.innerWidth < 960 && (
        <Button
          leftIcon={<Map />}
          variant="gray"
          className={styles.backToMapButton}
          onClick={() => {
            setMinimizeMap(false)
          }}
        >
          Map
        </Button>
      )}
    </div>
  )
}
export default SeatMap3d

const Thumbnail3d = ({
  config,
  ticketGroup,
  translationRules,
  selected,
  selectSection,
  unselectSection,
}) => {
  const viewer = useRef(null)
  const moduleLoaded = useRef(false)
  const containerId = useRef(`viewer-${nanoid()}`)
  const thumbnailId = useRef(`thumbnail-${nanoid()}`)
  const mappedSections = useRef({})
  const [mapExists, setMapExists] = useState(false)
  const [viewNotAvailable, setViewNotAvailable] = useState(false)

  function translateSecName(section) {
    const rules = translationRules
    let translatedSec

    if (rules && rules.sections) {
      const match = rules.sections
        .filter((r) => {
          const regex = new RegExp(r.rule, "gi")
          const matches = regex.exec(section)
          return matches
        })
        .map((r) => {
          const regex = new RegExp(r.rule, "gi")
          const matches = regex.exec(section)
          let newSec
          if (r.replace.length > 0) {
            newSec = r.replace.reduce((tem, rep) => {
              return tem.replace("${" + rep + "}", matches.groups[rep])
            }, r.template)
          } else {
            // Full replacement of section name
            newSec = r.template
          }

          return newSec
        })
      if (match.length > 0) {
        translatedSec = match[0]
      } else {
        translatedSec = `S_${section}`
      }
    } else {
      translatedSec = `S_${section}`
    }
    if (mappedSections.current[translatedSec]) {
      mappedSections.current[translatedSec].add(section)
    } else {
      mappedSections.current[translatedSec] = new Set([section])
    }
    return translatedSec
  }

  useEffect(() => {
    if (!ticketGroup) {
      viewer.current?.reset()
      //   moduleLoaded.current = false
    }

    var input_options = {
      container: containerId.current,
    }

    if (config?.venueId && !moduleLoaded.current) {
      window.DVM.loadModule("3d_viewer", input_options)
        .then(function (viewer3d) {
          const load_options = {
            venue_id: config.venueId,
            view_id: translateSecName(ticketGroup),
          }

          moduleLoaded.current = true
          viewer.current = viewer3d

          viewer.current.checkView3d(load_options).then((view3d) => {
            if (view3d) {
              viewer.current.getThumbnail(load_options).then((thumbnail) => {
                const img = new Image()
                img.src = thumbnail
                document
                  .getElementById(thumbnailId.current)
                  .appendChild(thumbnail)
              })

              setTimeout(() => {
                setMapExists(true)
              }, 1000)
            } else {
              setViewNotAvailable(true)
            }
          })
        })
        .catch(function (err) {
          console.error(err, `ERROR loading module ${err}`)
        })
    }

    return () => {
      viewer.current?.reset()
      moduleLoaded.current = false
    }
  }, [])

  if (viewNotAvailable) {
    return null
  }

  return (
    <>
      <div
        id={containerId.current}
        className={classNames(styles.seatmap3d, mapExists && styles.showMap)}
        style={{ display: "none" }}
      ></div>
      <div
        id={thumbnailId.current}
        onClick={() => {
          selectSection(ticketGroup)
        }}
        className={classNames(
          styles.thumbnail,
          selected && styles.selectedThumbnail
        )}
      >
        <span>{ticketGroup}</span>
        <CloseCircle
          onClick={() => {
            unselectSection()
          }}
        />
      </div>
    </>
  )
}

export { Thumbnail3d }